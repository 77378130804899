import React from "react";

const Video = props => {
	return (
		<div className="detail__video">
			<video autoPlay playsInline loop muted src={props.video} />
		</div>
	);
};

export default Video;
