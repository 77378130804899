import React from "react";
import data from "../../data.json";
import AnalyticInit from "../utils/helper.js";

class Projects extends React.Component {
	state = {
		contact: data.contact
	};
	componentDidMount() {
		new window.WOW().init();

		let width = window.innerWidth;

		if (width > 600) {
			let contact = document.querySelector(".contact");
			setTimeout(function() {
				contact.addEventListener("mousemove", function(e) {
					// Tilt Effect
					var sxPos = ((e.clientX * 2) / window.innerWidth) * 100 - 100;
					var syPos = ((e.clientY * 2) / window.innerHeight) * 100 - 100;
					var contact_tilt = window.$(".tilt");

					window.TweenMax.to(contact_tilt, 2, {
						rotationY: 0.06 * sxPos,
						rotationX: -0.06 * syPos,
						transformPerspective: 500,
						transformOrigin: "center center -50",
						ease: window.Expo.easeOut
					});
				});
			}, 1000);
		}
	}

	render() {
		return (
			<section id="contact" className="contact section">
				<div className="contact__container">
					<div className="contact__content">
						<div className="contact__upper tilt">
							<span className="second">{this.state.contact.textBig}</span>
							<span className="first">{this.state.contact.textSmall}</span>
						</div>
						<div className="contact__about">
							<div className="contact__call tilt">
								<a onClick={() => AnalyticInit("send", "event", "button", "click", " ana-sayfa ", "phone")} href={`tel:${this.state.contact.contactInfo[0].content}`}>
									<span className="first">
										{this.state.contact.contactInfo[0].text}
										<br />
										<span className="second">{this.state.contact.contactInfo[0].content}</span>
									</span>
								</a>
							</div>
							<div className="contact__email tilt">
								<span>
									<a onClick={() => AnalyticInit("send", "event", "button", "click", " ana-sayfa ", "mail")} className="first" href="mailto:info@madde22.com">
										{this.state.contact.contactInfo[1].text}
										<br />
										<span className="second">{this.state.contact.contactInfo[1].content}</span>
									</a>
								</span>
							</div>
							<div className="contact__visit tilt">
								<span className="first">
									<a
										onClick={() => AnalyticInit("send", "event", "button", "click", " ana-sayfa ", "address")}
										className="first"
										href={this.state.contact.contactInfo[2].address}
										target="_blank"
										rel="noopener noreferrer"
									>
										{this.state.contact.contactInfo[2].text}
										<br />
										<span className="second">{this.state.contact.contactInfo[2].content}</span>
									</a>
								</span>
							</div>
						</div>
					</div>
					<div className="contact__title section-title contact-anim">
						<h1>{this.state.contact.backgroundTitle}</h1>
					</div>
				</div>
			</section>
		);
	}
}
export default Projects;
