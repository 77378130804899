import React from "react";

let uri = window.location.origin;

const Macbook = props => {
	return (
		<div className="detail__macbook">
			<div className="detail__macbook-images">
				<div className="screen">
					<div className="screen__background">
						<div id="scroll" style={{ backgroundImage: "url(" + uri + props.images + ")" }} className="scroll__wrapper" />
						<div className="screen__background-video">
							<video playsInline autoPlay loop muted src={props.video} />
						</div>
					</div>
					<img src={uri + "/assets/images/Macbook-ekran-.png"} alt="" />
				</div>
				<div className="keyboard">
					<img src={uri + "/assets/images/Macbook-klavye.png"} alt="" />
				</div>
			</div>
		</div>
	);
};

export default Macbook;
