import React from "react";

class Iphone extends React.Component {
	render() {
		let uri = window.location.origin;

		return (
			<div className="detail__iphone">
				<img src={uri + "/assets/images/madde-22-atalante-1.png"} alt="" />
			</div>
		);
	}
}

export default Iphone;
