import React from "react";
import Header from "./Components/navigation/Header";
import Dashboard from "./Components/pages/Dashboard";
import Projects from "./Components/pages/Projects";
import Partners from "./Components/pages/Partners";
import Whatmake from "./Components/pages/Whatmake";
import Contact from "./Components/pages/Contact";
import AnalyticInit from "./Components/utils/helper";

class Main extends React.Component {
	componentDidMount() {
		AnalyticInit("send", "pageview", "page", "visit", "ana-sayfa", "ana-sayfa");
	}

	render() {
		return (
			<React.Fragment>
				<Header />
				<div className="container">
					<div className="slider-wrapper">
						<Dashboard />
					</div>
					<div className="slider-wrapper" id="clients">
						<Partners />
					</div>
					<div className="slider-wrapper" id="projects">
						<Projects />
					</div>
					<div className="slider-wrapper" id="services">
						<Whatmake />
					</div>
					<div className="slider-wrapper" id="contact">
						<Contact />
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Main;
