import React from "react";

class Ipad extends React.Component {
	componentDidMount() {
		new window.Swiper(".swiper", {
			speed: 800,
			loop: true,
			autoplay: {
				delay: 2000
			}
		});
	}
	render() {
		return (
			<section className="detail__ipad">
				<div className="detail__ipad-images">
					<img src={window.location.origin + "/assets/images/ipad-mock-up.png"} alt="" />
					<div className="swiper">
						<div className="swiper-wrapper">
							{this.props.gallery.map((e, i) => (
								<div key={i} className="swiper-slide">
									<img src={window.location.origin + e.image} alt="" />
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Ipad;
