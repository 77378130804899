import React from "react";

class Slider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			slideView: props.View || 1.5
		};
	}
	componentDidMount() {
		new window.Swiper("#detail-container", {
			spaceBetween: 100,
			speed: 800,
			direction: "horizontal",
			loop: true,
			centeredSlides: true,
			loopedSlides: 4,
			slidesPerView: this.state.slideView,
			scrollbar: {
				draggable: false
			},
			breakpoints: {
				1280: {
					spaceBetween: 80
				},
				1024: {
					spaceBetween: 50,
					slidesPerView: this.state.slideView - 0.1
				},
				768: {
					spaceBetween: 40,
					slidesPerView: this.state.slideView - 0.2
				},
				600: {
					spaceBetween: 20,
					slidesPerView: this.state.slideView - 0.3
				},
				320: {
					slidesPerView: this.state.slideView - 0.4,
					spaceBetween: 10
				}
			},
			autoplay: {
				delay: 2000
			}
		});
	}
	render() {
		return (
			<section className="detail__slider">
				<div id="detail-container" className="swiper-container">
					<div className="swiper-wrapper">
						{this.props.gallery.map((e, i) => (
							<div key={i} className="swiper-slide">
								<div className="swiper-slide-images">
									<img src={window.location.origin + e.image} alt="" />
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		);
	}
}

export default Slider;
