import React from "react";
import data from "../../data.json";

class Projects extends React.Component {
	state = {
		partners: data.partners
	};
	componentDidMount() {
		let partnets = document.querySelector(".partners");

		let width = window.innerWidth;
		if (width > 600) {
			setTimeout(function() {
				partnets.addEventListener("mousemove", function(e) {
					// Tilt Effect
					var sxPos = ((e.clientX * 2.5) / window.innerWidth) * 100 - 100;
					var syPos = ((e.clientY * 2.5) / window.innerHeight) * 100 - 100;

					// window.TweenMax.to(window.$(".partners__title"), 2, {
					// 	rotationY: 0.06 * sxPos,
					// 	rotationX: -0.06 * syPos,
					// 	transformPerspective: 1000,
					// 	transformOrigin: "center center 0",
					// 	ease: window.Expo.easeOut
					// });

					window.TweenMax.to(window.$(".partners__logo"), 2, {
						rotationY: 0.06 * sxPos,
						rotationX: -0.06 * syPos,
						transformPerspective: 100,
						transformOrigin: "center center -100",
						ease: window.Expo.easeOut
					});
				});
			}, 1000);
		}
	}

	render() {
		return (
			<section id="partners" className="partners section">
				<div className="partners__title section-title partners-anim ">
					<h1>{this.state.partners.backgroundTitle}</h1>
				</div>
				<div className="partners__list">
					{this.state.partners.list.map((e, i) => (
						<div key={i} className="partners__logo">
							<img src={e.image} alt={e.name} />
						</div>
					))}
				</div>
			</section>
		);
	}
}
export default Projects;
