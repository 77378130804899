import React from "react";
import data from "../../data.json";
import AnalyticInit from "../utils/helper.js";

class Dashboard extends React.Component {
	state = {
		slider: data.slider
	};
	componentDidMount = () => {
		window.$("body").on("click", ".owl-dot", function(e) {
			AnalyticInit("send", "event", "button", "click", " ana-sayfa ", "we-slider");
		});

		window.$(".owl-carousel").owlCarousel({
			loop: true,
			items: 3,
			margin: 10,
			nav: false,
			dots: true,
			animateOut: "fadeOut",
			animateIn: "fadeIn",
			autoplay: true,
			autoplayTimeout: 6000,
			responsive: {
				0: {
					items: 1
				},
				600: {
					items: 1
				},
				1000: {
					items: 1
				}
			}
		});

		window.$(document).bind("scroll touchmove", function() {
			let scrollPos = window.$(this).scrollTop();

			if (scrollPos >= 30) {
				window.$(".item-one,.item-two,.item-three,.item-four").addClass("anim-end");
			} else {
				window.$(".item-one,.item-two,.item-three,.item-four").removeClass("anim-end");
			}
		});

		window.$(document).bind("scroll touchmove", function() {
			let scrollPos = window.$(this).scrollTop();
			let partnersPos = window.$("#partners").offset().top;
			let projectsPos = window.$("#projects").offset().top;
			let servicesPos = window.$("#whatmake").offset().top;
			let contactPos = window.$("#contact").offset().top;
			if (scrollPos >= partnersPos - 400) {
				window.$(".partners-anim").addClass("opened");
			} else {
				window.$(".partners-anim").removeClass("opened");
			}
			if (scrollPos >= projectsPos - 420) {
				window.$(".projects-anim").addClass("opened");
				window.$(".partners-anim").removeClass("opened");
			} else {
				window.$(".projects-anim").removeClass("opened");
			}
			if (scrollPos >= servicesPos - 420) {
				window.$(".services-anim").addClass("opened");
				window.$(".projects-anim").removeClass("opened");
			} else {
				window.$(".services-anim").removeClass("opened");
			}
			if (scrollPos >= contactPos - 420) {
				window.$(".contact-anim").addClass("opened");
				window.$(".services-anim").removeClass("opened");
			} else {
				window.$(".contact-anim").removeClass("opened");
			}
		});
	};

	render() {
		return (
			<React.Fragment>
				<div className="slider section">
					<div className="owl-carousel owl-theme">
						<div className="item-one item ">
							<div className="item-content">
								<div className="item-text">
									<div className="text-2">{this.state.slider[0].title}</div>
								</div>
							</div>

							<div className="item-big-text section-title">{this.state.slider[0].backTitle}</div>
							<div className="item-ai">
								<svg id="Person" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1483.4 1680.26">
									<polyline id="line-9" className="cls-1" points="564.03 500.66 532.66 500.66 482.85 550.47 322.32 550.47" />
									<polyline id="line-8" className="cls-1" points="495.91 422.54 496.02 448.77 468.29 476.49 331.04 476.49" />
									<line id="line-7" className="cls-1" x1="432.19" y1="590.02" x2="600.65" y2="590.02" />
									<path
										id="line-6"
										className="cls-1"
										d="M1325,478.76c.72-5.72,17.55-140.44,20.15-150.91a136.69,136.69,0,0,1,132.92-103.79c75.53,0,137,61.44,137,137V646.89"
										transform="translate(-1014.37 150.62)"
									/>
									<polyline id="line-5" className="cls-1" points="564.03 678.64 532.66 678.64 482.85 628.84 310.62 628.84" />
									<path id="line-4" className="cls-1" d="M1374.78,477.48v24.9a51.27,51.27,0,0,0,51.27,51.28h56.09l48.52,48.52V750.76" transform="translate(-1014.37 150.62)" />
									<line id="line-3" className="cls-1" x1="432.19" y1="1379.39" x2="432.19" y2="704.28" />
									<line id="line-2" className="cls-1" x1="1483.05" y1="1679.91" x2="570.73" y2="767.59" />
									<line id="line-1" className="cls-1" x1="424.81" y1="424.81" x2="0.35" y2="0.35" />

									<path
										id="dot"
										data-name="dot"
										className="cls-2"
										d="M1510.39,256.37a10.69,10.69,0,1,1-10.68,10.69,10.68,10.68,0,0,1,10.68-10.69Z"
										transform="translate(-1014.37 150.62)"
									/>
									<path
										id="dot-2"
										data-name="dot"
										className="cls-2"
										d="M1585.09,339.35A10.69,10.69,0,1,1,1574.42,350a10.69,10.69,0,0,1,10.67-10.69Z"
										transform="translate(-1014.37 150.62)"
									/>
									<path
										id="dot-3"
										data-name="dot"
										className="cls-2"
										d="M1439.17,428.71a10.69,10.69,0,1,1-10.67,10.68,10.68,10.68,0,0,1,10.67-10.68Z"
										transform="translate(-1014.37 150.62)"
									/>
									<path
										id="dot-4"
										data-name="dot"
										className="cls-2"
										d="M1585.09,516.42a10.69,10.69,0,1,1-10.67,10.69,10.69,10.69,0,0,1,10.67-10.69Z"
										transform="translate(-1014.37 150.62)"
									/>
									<path
										id="dot-5"
										data-name="dot"
										className="cls-2"
										d="M1581.54,600.14a10.69,10.69,0,1,1-10.68,10.69,10.68,10.68,0,0,1,10.68-10.69Z"
										transform="translate(-1014.37 150.62)"
									/>
									<path
										id="dot-6"
										data-name="dot"
										className="cls-2"
										d="M1530.66,740.1A10.68,10.68,0,1,1,1520,750.78a10.68,10.68,0,0,1,10.67-10.68Z"
										transform="translate(-1014.37 150.62)"
									/>
									<path
										id="dot-7"
										data-name="dot"
										className="cls-2"
										d="M1439.17,263.5a10.69,10.69,0,1,1-10.67,10.68,10.68,10.68,0,0,1,10.67-10.68Z"
										transform="translate(-1014.37 150.62)"
									/>
								</svg>
							</div>
						</div>
						<div className="item-two item">
							<div className="item-content">
								<div className="item-text">
									<div className="text-1">{this.state.slider[1].topTitle}</div>
									<div className="text-2">{this.state.slider[1].title}</div>
									<div className="text-3"> {this.state.slider[1].bottomTitle}</div>
								</div>
							</div>

							<div className="item-big-text section-title" dangerouslySetInnerHTML={{ __html: this.state.slider[1].backTitle }} />

							<div className="item-ai">
								<svg id="Brain" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3050 2328">
									<polyline id="line-14" className="cls-1" points="1666.39 1211.37 1726.51 1211.37 1746.31 1231.17 1746.31 1250.96" />
									<polyline id="line-13" className="cls-1" points="1666.58 1168.12 1739.86 1168.12 1772.04 1200.3 1791.32 1200.3" />
									<polyline id="line-12" className="cls-1" points="1666.58 1122.87 1739.86 1122.87 1772.04 1090.69 1791.32 1090.69" />
									<polyline id="line-11" className="cls-1" points="1666.39 1079.63 1726.51 1079.63 1746.31 1059.83 1746.31 1040.03" />
									<polyline id="line-10" className="cls-1" points="1666.98 1232.7 1606.86 1232.7 1587.07 1252.49 1587.07 1272.29" />
									<polyline id="line-9" className="cls-1" points="1666.8 1189.45 1593.51 1189.45 1561.34 1221.63 1542.06 1221.63" />
									<polyline id="line-8" className="cls-1" points="1666.8 1144.2 1593.51 1144.2 1561.34 1112.02 1542.06 1112.02" />
									<polyline id="line-7" className="cls-1" points="1666.98 1100.95 1606.86 1100.95 1587.07 1081.16 1587.07 1061.36" />
									<line id="line-6" className="cls-1" x1="1666.39" y1="985.78" x2="1666.39" y2="2328" />
									<line id="line-5" className="cls-1" x1="1510.23" y1="1010.71" x2="2521.29" y2="442.08" />
									<polyline id="line-4" className="cls-1" points="3049.76 1736.87 1823.28 1067.69 1823.29 1010.71 1723.53 954.61 1666.39 985.78 1667.13 985.78 1609.99 954.61" />
									<polyline id="line-3" className="cls-1" points="1510.23 0 1510.23 1067.89 1510.24 1067.69 1453.1 1098.87 1453.1 1207.69" />
									<polyline id="line-2" className="cls-1" points="0.24 415 1510.23 1238.86 1510.23 1238.86 1510.23 1296.05 1609.99 1352.15" />
									<polyline id="line-1" className="cls-1" points="215.37 2127.49 1666.39 1320.98 1723.53 1352.15 1823.29 1296.05 1823.29 1238.86 1880.43 1207.69 1880.42 1098.87" />
									<path
										id="dot"
										data-name="dot"
										className="cls-2"
										d="M1608.43,315.5a10.69,10.69,0,1,1-10.67,10.68,10.68,10.68,0,0,1,10.67-10.68Z"
										transform="translate(137.87 704)"
									/>
									<path
										id="dot-2"
										data-name="dot"
										className="cls-2"
										d="M1449.19,336a10.69,10.69,0,1,1-10.67,10.69A10.69,10.69,0,0,1,1449.19,336Z"
										transform="translate(137.87 704)"
									/>
									<path
										id="dot-3"
										data-name="dot"
										className="cls-2"
										d="M1393.51,397.33A10.69,10.69,0,1,1,1382.83,408a10.68,10.68,0,0,1,10.68-10.69Z"
										transform="translate(137.87 704)"
									/>
									<path
										id="dot-4"
										data-name="dot"
										className="cls-2"
										d="M1393.51,506.94a10.69,10.69,0,1,1-10.68,10.69,10.68,10.68,0,0,1,10.68-10.69Z"
										transform="translate(137.87 704)"
									/>
									<path
										id="dot-5"
										data-name="dot"
										className="cls-2"
										d="M1449.19,568.34A10.69,10.69,0,1,1,1438.52,579a10.68,10.68,0,0,1,10.67-10.69Z"
										transform="translate(137.87 704)"
									/>
									<path
										id="dot-6"
										data-name="dot"
										className="cls-2"
										d="M1664.12,376a10.69,10.69,0,1,1-10.68,10.69A10.68,10.68,0,0,1,1664.12,376Z"
										transform="translate(137.87 704)"
									/>
									<path
										id="dot-7"
										data-name="dot"
										className="cls-2"
										d="M1664.12,485.61a10.69,10.69,0,1,1-10.68,10.69,10.69,10.69,0,0,1,10.68-10.69Z"
										transform="translate(137.87 704)"
									/>
									<path
										id="dot-8"
										data-name="dot"
										className="cls-2"
										d="M1608.43,547a10.69,10.69,0,1,1-10.67,10.69A10.69,10.69,0,0,1,1608.43,547Z"
										transform="translate(137.87 704)"
									/>
								</svg>
							</div>
						</div>
						<div className="item-three item">
							<div className="item-content">
								<div className="item-text">
									<div className="text-2" dangerouslySetInnerHTML={{ __html: this.state.slider[2].title }} />
								</div>
							</div>

							<div className="item-big-text section-title" dangerouslySetInnerHTML={{ __html: this.state.slider[2].backTitle }} />

							<div className="item-ai">
								<svg version="1.1" id="development" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
									<line id="line-3" className="cls-1" x1="2114.7" y1="-93.3" x2="308.6" y2="1712.8" />
									<polyline id="line-2" className="cls-1" points="1479.2,345.7 1275,462.6 1458.5,561.7 " />
									<polyline id="line-1" className="cls-1" points="1675.5,345.7 1879.7,462.6 1696.3,561.7 " />
									<path
										id="dot"
										data-name="dot"
										className="cls-2"
										d="M1479.2,335c5.9,0,10.7,4.8,10.7,10.7c0,5.9-4.8,10.7-10.7,10.7c-5.9,0-10.7-4.8-10.7-10.7
	C1468.6,339.8,1473.3,335,1479.2,335L1479.2,335z"
									/>
									<path
										id="dot-2"
										data-name="dot"
										className="cls-2"
										d="M1696.8,551c5.9,0,10.7,4.8,10.7,10.7c0,5.9-4.8,10.7-10.7,10.7c-5.9,0-10.7-4.8-10.7-10.7
	C1686.1,555.8,1690.9,551,1696.8,551L1696.8,551z"
									/>
								</svg>
							</div>
						</div>
						<div className="item-four item">
							<div className="item-content">
								<div className="item-text">
									<div className="text-2">{this.state.slider[3].title}</div>
								</div>
							</div>

							<div className="item-big-text section-title">{this.state.slider[3].backTitle}</div>

							<div className="item-ai">
								<svg id="network" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1451.92 1295.6">
									<rect id="dot" data-name="dot" className="cls-1" x="1135.13" y="686.08" width="18.72" height="18.72" />
									<rect id="dot-2" data-name="dot" className="cls-1" x="657.92" y="374.01" width="18.72" height="18.72" />
									<polygon id="dot-3" data-name="dot" className="cls-2" points="882.27 839.89 871.18 839.89 876.73 830.29 882.27 820.69 887.81 830.29 893.36 839.89 882.27 839.89" />
									<polygon
										id="dot-4"
										data-name="dot"
										className="cls-2"
										points="1067.5 405.41 1056.41 405.41 1061.96 395.81 1067.5 386.2 1073.05 395.81 1078.59 405.41 1067.5 405.41"
									/>
									<path
										id="dot-5"
										data-name="dot"
										className="cls-2"
										d="M1507.82,81.54a10.69,10.69,0,1,1-10.67,10.69,10.68,10.68,0,0,1,10.67-10.69Z"
										transform="translate(-630.9 96.23)"
									/>
									<path
										id="dot-6"
										data-name="dot"
										className="cls-2"
										d="M1525.63,407A10.69,10.69,0,1,1,1515,417.65,10.68,10.68,0,0,1,1525.63,407Z"
										transform="translate(-630.9 96.23)"
									/>
									<path
										id="dot-7"
										data-name="dot"
										className="cls-2"
										d="M1438.3,630.49a10.69,10.69,0,1,1-10.68,10.68,10.68,10.68,0,0,1,10.68-10.68Z"
										transform="translate(-630.9 96.23)"
									/>
									<path
										id="dot-8"
										data-name="dot"
										className="cls-2"
										d="M1231.23,873.67a10.69,10.69,0,1,1-10.68,10.69,10.68,10.68,0,0,1,10.68-10.69Z"
										transform="translate(-630.9 96.23)"
									/>
									<polyline id="line-5" className="cls-3" points="0.23 1295.16 1144.49 695.43 892.82 513.87 807.4 737.4" />
									<polyline id="line-4" className="cls-3" points="1144.49 695.43 807.4 737.4 600.4 980.59" />
									<polyline id="line-1" className="cls-3" points="1078.59 0.37 666.32 383.37 807.4 737.4 1228.05 1295.16" />
									<polyline id="line-2" className="cls-3" points="28.86 16.08 892.82 513.87 1067.48 398 876.62 188" />
									<polyline id="line-3" className="cls-3" points="1451.86 657.17 1144.49 695.43 1067.48 398 666.32 383.37" />
								</svg>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Dashboard;
