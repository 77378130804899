import React from "react";
import { Layout } from "antd";
import Globe from "../../globe.svg";
import Close from "../../close.svg";
import data from "../../data.json";
import AnalyticInit from "../utils/helper";
const { Header } = Layout;

class HamburgerMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			menuEnable: false,
			contactClick: false,
			menu: data.menu
		};

		this.menuClick = this.menuClick.bind(this);
	}

	menuClick = () => {
		AnalyticInit("send", "event", "button", "click", " ana-sayfa ", "menu");

		if (this.props.detailMenu !== false) {
			this.setState({
				menuEnable: !this.state.menuEnable,
				contactClick: false
			});
		} else {
			this.props.handleClick();
			window.$(".hamburger").removeClass("opened");
			window.$("body").removeClass("detail");
			window.$("html").css("overflow", "auto");
		}
	};

	componentDidMount() {
		// var isIE = /*@cc_on!@*/ false || !!document.documentMode;

		let _this = this;

		let url = window.location.href;

		if (url.indexOf("detail") === -1) {
			window.$(".menu-elem ul li a").on("click", function(e) {
				e.preventDefault();

				_this.setState({
					menuEnable: false
				});

				let data = window
					.$(this)
					.attr("href")
					.split("#")[1];

				window.$("html, body").animate(
					{
						scrollTop: window.$("#" + data).offset().top
					},
					600
				);
			});
		} else {
			window.$(".menu-elem ul li a").on("click", function(e) {
				e.preventDefault();

				_this.setState({
					menuEnable: false
				});

				let data = window
					.$(this)
					.attr("href")
					.split("#")[1];

				window.location.href = window.location.origin + "/#" + data;
			});
		}

		window.$(".contact-icon button").hover(
			() => {
				window.$(".contact-icon").addClass("toggleIcon");
			},
			() => {
				window.$(".contact-icon").removeClass("toggleIcon");
			}
		);

		window.$(".contact-icon button").off("click");

		window.$(".contact-icon button").on("click", function(e) {
			AnalyticInit("send", "event", "button", "click", " ana-sayfa ", "contact");

			e.preventDefault();
			if (
				window
					.$(this)
					.parent()
					.hasClass("active")
			) {
				window
					.$(this)
					.parent()
					.removeClass("active");

				_this.setState({
					contactClick: false
				});
			} else {
				window
					.$(this)
					.parent()
					.addClass("active");

				_this.setState({
					contactClick: true
				});
			}
		});
	}

	render() {
		return (
			<Header id="header" className={[["header", this.state.menuEnable === true ? "light active" : "light"]]}>
				<div className="main-container main-container-component">
					<div className={`logo ${this.props.activeMenu === 3 || this.props.activeMenu === 5 ? "filled-logo" : null}`}>
						<div className={`menu hamburger ${this.props.activeMenu === 4 ? "light-menu" : null} ${this.state.menuEnable === true ? "opened" : ""}`} onClick={this.menuClick}>
							<span />
						</div>
						<a href="/" onClick={() => AnalyticInit("send", "event", "button", "click", " ana-sayfa ", "logo")}>
							<svg version="1.1" id="Logo" x="0px" y="0px" width="62px" height="50px" viewBox="0 0 135.731 112.469" enableBackground="new 0 0 135.731 112.469">
								<path
									id="D1"
									fill="#F0F0F0"
									d="M129.982,5.674c-3.399-3.549-8.12-5.425-13.648-5.425H99.6v52.486h16.734
                                    c5.528,0,10.249-1.86,13.648-5.379c3.169-3.279,4.914-7.78,4.914-12.672V18.446C134.896,13.517,133.151,8.981,129.982,5.674
                                     M123.663,18.373v16.384c0,5.114-3.682,7.403-7.329,7.403h-5.574V10.824h5.574c2.229,0,4.107,0.781,5.435,2.258
                                    C122.99,14.442,123.663,16.321,123.663,18.373"
								/>
								<path
									id="D2"
									fill="#F0F0F0"
									d="M30.384,65.409c-3.4-3.549-8.12-5.425-13.649-5.425H0.001v52.485h16.734
                                    c5.529,0,10.249-1.859,13.649-5.379c3.169-3.278,4.914-7.779,4.914-12.672V78.18C35.298,73.251,33.553,68.715,30.384,65.409
                                     M24.065,78.108v16.384c0,5.114-3.682,7.403-7.33,7.403h-5.574V70.559h5.574c2.229,0,4.108,0.78,5.435,2.258
                                    C23.392,74.176,24.065,76.055,24.065,78.108"
								/>
								<polygon
									id="E"
									fill="#F0F0F0"
									points="69.01,70.224 69.01,59.801 39.068,59.801 39.068,112.429 69.01,112.429 69.01,102.006
                                    50.154,102.006 50.154,90.701 64.739,90.701 64.739,80.204 50.154,80.204 50.154,70.224 "
								/>
								<path
									id="_x32_1"
									fill="#F0F0F0"
									d="M102.376,73.962c0-4.217-1.564-8.114-4.403-10.974c-2.845-2.862-6.729-4.439-10.937-4.439
                                    c-4.18,0-8.054,1.577-10.908,4.439c-2.858,2.867-4.432,6.765-4.432,10.974v2.597h10.865v-2.597c0-3.169,1.715-5.137,4.475-5.137
                                    s4.474,1.968,4.474,5.137c0,2.208-0.405,3.689-1.44,5.279l-17.488,24.129v9.059h29.795v-10.276H86.408L99.326,84.59
                                    C101.35,81.718,102.376,78.142,102.376,73.962"
								/>
								<path
									id="_x32_2"
									fill="#F0F0F0"
									d="M132.682,84.59c2.023-2.873,3.05-6.449,3.05-10.628c0-4.217-1.563-8.114-4.403-10.973
                                    c-2.844-2.863-6.728-4.441-10.936-4.441c-4.181,0-8.055,1.578-10.908,4.441c-2.858,2.866-4.432,6.764-4.432,10.973v2.597h10.866
                                    v-2.597c0-3.169,1.714-5.137,4.474-5.137s4.474,1.968,4.474,5.137c0,2.208-0.405,3.689-1.442,5.279l-17.487,24.129v9.059h29.795
                                    v-10.275h-15.968L132.682,84.59z"
								/>
								<polygon
									id="M"
									fill="#F0F0F0"
									points="11.414,23.063 22.829,38.464 25.095,38.464 36.588,22.985 36.588,52.735 48.002,52.735
                                    48.002,0.001 40.34,0.001 23.923,23.142 7.74,0.001 0.001,0.001 0.001,52.735 11.414,52.735 "
								/>
								<path
									id="A"
									fill="#F0F0F0"
									d="M79.384,0.001H68.283L52.017,52.735h11.934l2.21-8.33h15.13l2.268,8.33h11.942L79.384,0.001z M69.096,33.694l4.58-17.119l4.656,17.119H69.096z"
								/>
							</svg>
						</a>
					</div>

					<div className={this.state.menuEnable === true ? "menu-elem opened" : "menu-elem"}>
						<nav>
							<ul>
								{this.state.menu.map((e, i) => (
									<li key={i}>
										<a onClick={() => AnalyticInit("send", "event", "button", "click", " nav-menu ", e.text.toLowerCase())} href={`#${e.text.toLowerCase()}`}>
											{e.text}
										</a>
									</li>
								))}
							</ul>
						</nav>
					</div>
					<div className={this.state.menuEnable === true ? "menu-elem-overlay opened" : "menu-elem-overlay"} />
				</div>
				<div className={this.state.menuEnable === true ? "contact-icon opened" : "contact-icon"}>
					<div className="contact__info">
						<ul>
							<li>
								<span>Telefon</span>
								<span>
									<a href="tel:+90 (212) 243 28 13">0212 243 2813</a>
								</span>
							</li>

							<li>
								<span>E-Posta</span>
								<span>
									<a href="mailto:info@madde22.com">info@madde22.com</a>
								</span>
							</li>

							<li>
								<span>Adres</span>

								<a
									href="https://www.google.com/maps/place/Madde+22/@41.0265143,28.9760082,17z/data=!4m5!3m4!1s0x14cab672456640b3:0x91959dba9dc9f640!8m2!3d41.027259!4d28.97574"
									target="_blank"
									rel="noopener noreferrer"
								>
									<address>
										<p>Şahkulu Mah. Serdar-ı Ekrem Cad.</p>
										<p>No: 27/5 Galata Beyoğlu İstanbul</p>
									</address>
								</a>
							</li>
						</ul>
					</div>

					<button>
						<img src={this.state.contactClick ? Close : Globe} alt="" />
					</button>
				</div>
			</Header>
		);
	}
}

export default HamburgerMenu;
