/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import data from "../../data.json";

class Projects extends React.Component {
	state = {
		services: data.services
	};
	componentDidMount() {
		new window.WOW().init();

		window.$(document).bind("scroll touchmove", function() {
			let servicesPos = window.$("#whatmake").offset().top;
			let scrollPos = window.$(this).scrollTop();

			if (scrollPos >= servicesPos - 423) {
				setTimeout(() => {
					window.$("#svg-one,#svg-two,#svg-three,#svg-four").addClass("anim-start");
				}, 0);
			}
		});

		let width = window.innerWidth;

		if (width > 600) {
			let whatmake = document.querySelector(".whatmake");
			setTimeout(function() {
				whatmake.addEventListener("mousemove", function(e) {
					// Tilt Effect
					var sxPos = ((e.clientX * 2.5) / window.innerWidth) * 100 - 100;
					var syPos = ((e.clientY * 2.5) / window.innerHeight) * 100 - 100;

					// window.TweenMax.to(window.$(".whatmake__title"), 2, {
					// 	rotationY: 0.06 * sxPos,
					// 	rotationX: -0.06 * syPos,
					// 	transformPerspective: 1000,
					// 	transformOrigin: "center center 0",
					// 	ease: window.Expo.easeOut
					// });

					window.TweenMax.to(window.$(".whatmake__content-wrapper"), 2, {
						rotationY: 0.06 * sxPos,
						rotationX: -0.06 * syPos,
						transformPerspective: 500,
						transformOrigin: "center center -50",
						ease: window.Expo.easeOut
					});
				});
			}, 1000);
		}
	}
	render() {
		return (
			<section id="whatmake" className="whatmake section">
				<div className="whatmake__container">
					<div className="whatmake__content">
						<div className="whatmake__title section-title services-anim">
							<h1 className="wow ">{this.state.services.backgroundTitle}</h1>
						</div>
						<div className="whatmake__content-wrapper first">
							<div className="whatmake__image">
								<svg
									version="1.1"
									height="150"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									viewBox="0 0 192.5 177.4"
									style={{ enableBackground: "new 0 0 192.5 177.4" }}
									id="svg-one"
								>
									<polyline id="line-14_2_" className="st0" points="96.2,114.6 122.8,114.6 131.5,123.4 131.5,132.2 " />
									<polyline id="line-13_2_" className="st0" points="96.2,95.5 128.7,95.5 142.9,109.7 151.4,109.7 " />
									<polyline id="line-12_2_" className="st0" points="96.2,75.5 128.7,75.5 142.9,61.3 151.4,61.3 " />
									<polyline id="line-11_2_" className="st0" points="96.2,56.4 122.8,56.4 131.5,47.6 131.5,38.8 " />
									<polyline id="line-10_2_" className="st0" points="96.4,124.1 69.8,124.1 61.1,132.8 61.1,141.6 " />
									<polyline id="line-9_2_" className="st0" points="96.3,104.9 63.9,104.9 49.7,119.2 41.2,119.2 " />
									<polyline id="line-8_2_" className="st0" points="96.3,84.9 63.9,84.9 49.7,70.7 41.2,70.7 " />
									<polyline id="line-7_2_" className="st0" points="96.4,65.8 69.8,65.8 61.1,57 61.1,48.3 " />
									<line id="line-6_2_" className="st0" x1="96.2" y1="14.8" x2="96.2" y2="163.1" />
									<line id="line-5_2_" className="st0" x1="27.1" y1="25.9" x2="71.2" y2="1.1" />
									<polyline id="line-4_2_" className="st0" points="190.8,64.9 165.6,51.1 165.6,25.9 121.4,1.1 96.2,14.8 96.5,14.8 71.2,1.1 " />
									<polyline id="line-3_2_" className="st0" points="27.1,25.9 27.1,51.2 27.1,51.1 1.8,64.9 1.8,113 " />
									<polyline id="line-2_2_" className="st0" points="1.8,113 27.1,126.8 27.1,126.8 27.1,152.1 71.2,176.9 " />
									<polyline id="line-1_2_" className="st0" points="71.2,176.9 96.2,163.1 121.4,176.9 165.6,152.1 165.6,126.8 190.8,113 190.8,64.9 " />
									<circle id="dot" className="st1" cx="61.1" cy="43.6" r="4.6" />
									<circle id="dot_1_" className="st1" cx="131.5" cy="38.8" r="4.6" />
									<circle id="dot_2_" className="st1" cx="151.4" cy="61.3" r="4.6" />
									<circle id="dot_3_" className="st1" cx="151.4" cy="109.7" r="4.6" />
									<circle id="dot_5_" className="st1" cx="131.5" cy="132.2" r="4.6" />
									<circle id="dot_4_" className="st1" cx="36.4" cy="70.7" r="4.6" />
									<circle id="dot_6_" className="st1" cx="36.5" cy="119.2" r="4.6" />
									<circle id="dot_7_" className="st1" cx="61.1" cy="146.3" r="4.6" />
								</svg>
							</div>
							<div className="whatmake__information">
								{this.state.services.list[0].list.map((e, i) => (
									<span key={i}>{e.text} </span>
								))}
							</div>
						</div>
						<div className="whatmake__content-wrapper two">
							<div className="whatmake__image">
								<svg
									version="1.1"
									id="barcode"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									height="120"
									viewBox="0 0 275.8 141.5"
									style={{ enableBackground: "new 0 0 275.8 141.5" }}
									id="svg-two"
								>
									<line id="line-3_1_" className="st0" x1="202.5" y1="1.2" x2="64.1" y2="139.6" />
									<polyline id="line-2_1_" className="st0" points="94.2,21.8 2.9,74 84.9,118.3 " />
									<polyline id="line-1_1_" className="st0" points="181.9,21.8 273.1,74 191.2,118.3 " />
									<circle id="dot" className="st1" cx="94.2" cy="21.8" r="4.6" />
									<circle id="dot_1_" className="st1" cx="191.2" cy="118.3" r="4.6" />
								</svg>
							</div>
							<div className="whatmake__information">
								{this.state.services.list[1].list.map((e, i) => (
									<span key={i}>{e.text} </span>
								))}
							</div>
						</div>
						<div className="whatmake__content-wrapper three">
							<div className="whatmake__image">
								<svg
									version="1.1"
									id="barcode"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									height="130"
									viewBox="0 0 221.8 153.3"
									style={{ enableBackground: "new 0 0 221.8 153.3" }}
									id="svg-three"
								>
									<line id="line-20" className="st0" x1="196.9" y1="20.8" x2="196.9" y2="136.4" />
									<line id="line-19" className="st0" x1="192.9" y1="20.8" x2="192.9" y2="136.4" />
									<line id="line-18" className="st0" x1="185.9" y1="20.8" x2="185.9" y2="119.1" />
									<line id="line-17" className="st0" x1="169" y1="20.8" x2="169" y2="119.1" />
									<line id="line-16" className="st0" x1="162.6" y1="20.8" x2="162.6" y2="119.1" />
									<line id="line-15" className="st0" x1="151.6" y1="20.8" x2="151.6" y2="119.1" />
									<line id="line-14" className="st0" x1="147.5" y1="20.8" x2="147.5" y2="119.1" />
									<line id="line-13" className="st0" x1="140.6" y1="20.8" x2="140.6" y2="119.1" />
									<line id="line-12" className="st0" x1="129.3" y1="20.8" x2="129.3" y2="119.1" />
									<line id="line-11" className="st0" x1="117.5" y1="20.8" x2="117.5" y2="119.1" />
									<line id="line-10" className="st0" x1="102.7" y1="20.8" x2="102.7" y2="119.1" />
									<line id="line-9" className="st0" x1="96.3" y1="20.8" x2="96.3" y2="119.1" />
									<line id="line-8" className="st0" x1="85.2" y1="20.8" x2="85.2" y2="119.1" />
									<line id="line-7" className="st0" x1="81.2" y1="20.8" x2="81.2" y2="119.1" />
									<line id="line-6" className="st0" x1="74.2" y1="20.8" x2="74.2" y2="119.1" />
									<line id="line-5" className="st0" x1="55.8" y1="20.8" x2="55.8" y2="119.1" />
									<line id="line-4" className="st0" x1="41" y1="20.8" x2="41" y2="119.1" />
									<line id="line-3" className="st0" x1="34.6" y1="20.8" x2="34.6" y2="136.4" />
									<line id="line-2" className="st0" x1="25.5" y1="20.8" x2="25.5" y2="136.4" />
									<rect id="line-1" x="6.5" y="5.2" className="st0" width="210" height="143.6" />
									<circle id="dot_1_" className="st1" cx="216.5" cy="5.2" r="4.6" />
									<circle id="dot_2_" className="st1" cx="216.5" cy="148.8" r="4.6" />
									<circle id="dot_3_" className="st1" cx="6.5" cy="148.8" r="4.6" />
									<circle id="dot" className="st1" cx="6.5" cy="5.2" r="4.6" />
								</svg>
							</div>
							<div className="whatmake__information">
								{this.state.services.list[2].list.map((e, i) => (
									<span key={i}>{e.text} </span>
								))}
							</div>
						</div>
						<div className="whatmake__content-wrapper four">
							<div className="whatmake__image">
								<svg
									version="1.1"
									id="network_2"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									height="150"
									viewBox="0 0 282.9 277.2"
									style={{ enableBackground: "new 0 0 282.9 277.2" }}
									id="svg-four"
								>
									<polyline id="line-6" className="st0" points="141.1,7.9 275.5,176.4 33.3,59.8 " />
									<polyline id="line-5" className="st0" points="6.7,176.4 119.7,101.2 200.9,270 " />
									<polyline id="line-4" className="st0" points="248.9,59.8 81.3,270 141.1,7.9 " />
									<polygon id="line-3" className="st0" points="93.6,243 34.4,168.7 55.5,76.1 141.1,34.9 226.7,76.1 247.8,168.7 188.6,243 " />
									<polygon id="line-2" className="st0" points="107.3,213 65.1,160.1 80.2,94.2 141.1,64.9 202,94.2 217.1,160.1 174.9,213 " />
									<polygon id="line-1_1_" className="st0" points="81.3,270 6.7,176.4 33.3,59.8 141.1,7.9 248.9,59.8 275.5,176.4 200.9,270 " />
									<circle id="dot_9_" className="st1" cx="141.1" cy="7.9" r="6.3" />
									<circle id="dot_8_" className="st1" cx="6.7" cy="176.4" r="6.3" />
									<circle id="dot_7_" className="st1" cx="200.9" cy="270" r="6.3" />
									<circle id="dot_6_" className="st1" cx="248.9" cy="59.8" r="6.3" />
									<circle id="dot_5_" className="st1" cx="215.9" cy="101.2" r="6.3" />
									<ellipse id="dot_4_" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -79.5304 160.856)" className="st1" cx="154.4" cy="176.4" rx="6.2" ry="6.3" />
									<rect id="dot_3_" x="113.9" y="95.5" className="st1" width="11.4" height="11.4" />
									<rect id="dot_2_" x="76.2" y="264.3" className="st1" width="11.4" height="11.4" />
									<polygon id="dot_1_" className="st1" points="26.1,64.9 33.3,52.4 40.5,64.9 " />
									<polygon id="dot" className="st1" points="268.3,181.5 275.5,169 282.7,181.5 " />
								</svg>
							</div>
							<div className="whatmake__information">
								{this.state.services.list[3].list.map((e, i) => (
									<span key={i}>{e.text} </span>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
export default Projects;
