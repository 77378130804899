import Analytics from "m22-analytics";

const analysticInit = (...props) => {
	const analytics = new Analytics("b7ba1cb4-5198-4a90-97fe-2e0edccf6e25");

	analytics.mdd(...props);

	analytics.watch();
};

const AnalyticInit = (...props) => {
	analysticInit(...props);
};

export default AnalyticInit;
