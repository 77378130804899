import React from "react";
import { Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import Main from "./Main";
import Detail from "./Components/pages/Details/Detail";

const App = ({ location }) => {
	return (
		<div>
			<Switch>
				<Route path="/" exact component={Main} location={location} />
				<Route path="/projects/:id" exact component={Detail} location={location} />
			</Switch>
		</div>
	);
};
export default App;
