import React from "react";
import Helmet from "react-helmet";
import Header from "../../navigation/Header";
import Macbook from "./Macbook";
import Slider from "./Slider";
import Ipad from "./Ipad";
import Iphone from "./Iphone";
import Image from "./Image";
import data from "../../../data.json";
import _ from "lodash";
import Video from "./Video";
import AnalyticInit from "../../utils/helper";

class Detail extends React.Component {
	constructor(props) {
		super(props);
		const prj = _.find(data.projects, function(x) {
			return x.projectSlug === (typeof props.match === "undefined" ? props.projectId : props.match.params.id);
		});
		this.state = {
			uri: window.location.origin,
			project: prj,
			detailMenu: false
		};
	}
	componentDidMount() {
		AnalyticInit("send", "pageview", "page", "visit", "proje", this.state.project.projectSlug);

		var isIE = /*@cc_on!@*/ false || !!document.documentMode;
		if (isIE === true) {
			window.$("body").addClass("ie");
		}
		window.$(".transition").scrollTop(0);
		window.$("body").addClass("detail");
		window.$(".hamburger").addClass("opened");

		var currHeight;
		var currWidth;

		function getHeight() {
			if (window.$("#scroll").length)
				var url = window
					.$("#scroll")
					.css("background-image")
					.replace("url(", "")
					.replace(")", "")
					.replace('"', "")
					.replace('"', "");
			var bgImg = window.$("<img />");
			bgImg.hide();
			bgImg.bind("load", function() {
				currHeight = window.$(this).height();
				currWidth = window.$(this).width();
			});
			window.$("body").append(bgImg);
			bgImg.attr("src", url);
		}

		getHeight();

		window.$(".transition").scroll(function() {
			getHeight();
			var scrollTop = window.$(".transition").scrollTop() * -0.5;
			var newHeight = (currHeight * window.$("#scroll").width()) / currWidth;
			var diff = parseInt(window.$("#scroll").css("background-position-y")) + newHeight;
			if (diff > window.$("#scroll").height()) {
				window.$("#scroll").css("background-position-y", scrollTop);
			}
		});

		window.$(window).scroll(function() {
			getHeight();
			var scrollTop = window.$(window).scrollTop() * -0.5;
			var newHeight = (currHeight * window.$("#scroll").width()) / currWidth;
			var diff = parseInt(window.$("#scroll").css("background-position-y")) + newHeight;
			if (diff > window.$("#scroll").height()) {
				window.$("#scroll").css("background-position-y", scrollTop);
			}
		});
	}

	onClick = e => {
		window.$(".Projects").removeClass("active");
		// window.$(".transition").removeClass("active");
		window.$("html").css("overflow", "auto");
		window.TweenMax.to(window.$(".transition"), 0.3, { right: "-100%", opacity: 0, ease: window.Circ.easeOut });
		if (this.props.clickStatus === 1) {
			this.props.onClick();
		} else {
			window.location.href = "/#projects";
		}
	};
	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{this.state.project.projectName} - Madde22 | Digital Agency</title>
				</Helmet>
				<Header handleClick={this.onClick} detailMenu={this.state.detailMenu} />
				<div id="detail" className="detail">
					{[]
						.concat(this.state.project.detail)
						.sort((a, b) => a.order - b.order)
						.map((item, i) => (
							<React.Fragment key={i}>
								{item.mockupType === "image" && <Image projectName={this.state.project.projectName.replace(" ", "")} image={item.image} type={item.type} />}
								{item.mockupType === "iphone" && <Iphone projectName={this.state.project.projectName.replace(" ", "")} />}
								{item.mockupType === "macbook" && <Macbook projectName={this.state.project.projectName.replace(" ", "")} images={item.images} video={item.video} />}

								{item.mockupType === "ipad" && <Ipad projectName={this.state.project.projectName.replace(" ", "")} gallery={item.gallery} />}

								{item.mockupType === "slider" && <Slider projectName={this.state.project.projectName.replace(" ", "")} gallery={item.gallery} View={item.fraction} />}
								{item.mockupType === "video" && <Video projectName={this.state.project.projectName.replace(" ", "")} video={item.video} />}
							</React.Fragment>
						))}
					{/* <div className="detail__project">
						<div className="detail__project-wrapper">
							<a href={`/detail/${this.state.prevProject.projectSlug}`}>{this.state.prevProject.projectCompany}</a>
							<a href={`/detail/${this.state.prevProject.projectSlug}`}>{this.state.prevProject.projectName}</a>
						</div>
						<div className="detail__project-wrapper">
							<a href={`/detail/${this.state.nextProject.projectSlug}`}>{this.state.nextProject.projectCompany}</a>
							<a href={`/detail/${this.state.nextProject.projectSlug}`}>{this.state.nextProject.projectName}</a>
						</div>
					</div> */}
				</div>
			</React.Fragment>
		);
	}
}

export default Detail;
