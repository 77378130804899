import React from "react";

class Image extends React.Component {
	render() {
		let uri = window.location.origin;
		return (
			<div className={`fullscreen-image ${this.props.type}`}>
				<img src={uri + this.props.image} alt="" />
			</div>
		);
	}
}

export default Image;
