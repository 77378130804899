import React from "react";
import data from "../../data.json";
import Detail from "./Details/Detail.js";
import AnalyticInit from "../utils/helper.js";

class Projects extends React.Component {
	constructor(props) {
		super(props);
		this.swiper = {};
		this.state = {
			projects: data.projects,
			detailIndex: "",
			clickStatus: 0
		};
		this.onClick = this.onClick.bind(this);
	}

	componentDidMount = () => {
		var isIE = /*@cc_on!@*/ false || !!document.documentMode;
		if (isIE === true) {
			window.$("body").addClass("ie");
		}
		var lastScrollTop = 0;
		var projectOffset = window.$("#partners").offset().top;
		var _this = this;

		window.$(window).on("scroll", function() {
			var st = window.$(this).scrollTop();

			if (st >= projectOffset + 150) {
				_this.initSlider();
				window.$(window).off("scroll");
			}
		});
	};

	initSlider = () => {
		this.swiper = new window.Swiper("#main-container", {
			slidesPerView: 1.5,
			spaceBetween: 0,
			speed: 650,
			direction: "horizontal",
			loop: true,
			loopedSlides: 0,
			centeredSlides: true,
			noSwiping: true,
			noSwipingClass: "swiper-button-next ",
			breakpoints: {
				600: {
					slidesPerView: 1
				}
			},
			autoplay: {
				delay: 3800
			},
			pagination: {
				el: ".swiper-pagination",
				clickable: true
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev"
			}
		});
		this.swiper.on("slideChange", function() {
			setTimeout(() => {
				window.$(".swiper-slide-active").addClass("open");
				window.$(".swiper-slide-prev").removeClass("open");
			}, 500);
		});

		window.$(".project-item").off("click");
		window.$(".project-item").on("click", e => {
			e.preventDefault();
			var index = window.$(e.currentTarget).data("index");
			this.onClick(e, index);
		});
	};

	onClick = (e, i) => {
		e.preventDefault();

		window.$(".Projects").addClass("active");
		// window.$(".transition").addClass("active");
		window.$("body").addClass("detail");
		window.$(".header").hide();
		window.$("#main-slider").hide();

		var width = window.innerWidth;
		if (width < 600) {
			setTimeout(() => {
				window.location.reload();
			}, 500);
		}
		setTimeout(() => {
			window.$("body").css("position", "fixed");
		}, 400);

		setTimeout(() => {
			this.swiper.destroy();
			window.$(".hamburger").addClass("opened");
			window.$("html").css("overflow", "hidden");
			window.$(window).scrollTop(0);
		}, 500);

		this.setState(
			{
				detailIndex: this.state.projects[i].projectSlug,
				clickStatus: 1
			},
			() => {
				window.history.pushState(null, "", "/projects/" + this.state.detailIndex);
				setTimeout(() => {
					window.TweenMax.to(window.$(".transition"), 0.4, { right: "0", opacity: 1, ease: window.Circ.easeOut });
				}, 50);
			}
		);
	};

	detailClick = () => {
		window.$("body").css("position", "inherit");
		window.$("#main-slider").show();
		window.$("#header").css("position", "fixed");
		window.$(window).scrollTop(window.$("#projects").offset().top);

		setTimeout(() => {
			this.initSlider();
			this.setState({
				detailIndex: "",
				clickStatus: 0
			});

			// window.$(".project-item").on("click", e => {
			// 	e.preventDefault();
			// 	var index = window.$(e.currentTarget).data("index");
			// 	this.onClick(e, index);
			// });

			window.history.pushState(null, "", "/");
			document.title = "Madde 22 Technology & Design Agency";
			window.$(".header").show();
		}, 300);
	};
	render() {
		return (
			<React.Fragment>
				<section id="projects" className="Projects section">
					<div className="Projects-Title section-title projects-anim">
						<h1>Projects</h1>
					</div>
					<div className="project-slider">
						<div id="main-container" className="swiper-container">
							<div className="swiper-wrapper">
								{this.state.projects
									.sort((a, b) => a.order - b.order)
									.map((e, i) => {
										return (
											<div className="swiper-slide" key={i}>
												<a href="#!" data-index={i} className={[`project-item ${e.projectSlug} `]}>
													<div className="item-image">
														{e.dashboardImage ? (
															<img src={e.dashboardImage} alt={e.projectName} />
														) : (
															<div className="project__video">
																<video playsInline autoPlay loop muted src={e.dashboardVideo} />
															</div>
														)}
														<div className={`item-text ${e.projectName === "Komtaş" ? "komtas" : ""}`}>
															<div className="big-title">{e.projectCompany}</div>
															<div className="small-title">{e.projectName}</div>
														</div>
													</div>
												</a>
											</div>
										);
									})}
							</div>
							<div onClick={() => AnalyticInit("send", "event", "button", "click", " ana-sayfa ", "project-prev")} className="swiper-button-prev ">
								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 15.3 26.1" style={{ enableBackground: "new 0 0 15.3 26.1" }}>
									<path className="st00" d="M1.1,1.1c4,4,8.1,8,12.1,12.1c-4,4-8,8-12,11.9" />
								</svg>
							</div>
							<div onClick={() => AnalyticInit("send", "event", "button", "click", " ana-sayfa ", "project-next")} className="swiper-button-next ">
								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 15.3 26.1" style={{ enableBackground: "new 0 0 15.3 26.1" }}>
									<path className="st00" d="M1.1,1.1c4,4,8.1,8,12.1,12.1c-4,4-8,8-12,11.9" />
								</svg>
							</div>
						</div>
					</div>
				</section>
				<div className="transition">{this.state.detailIndex !== "" && <Detail onClick={this.detailClick} projectId={this.state.detailIndex} clickStatus={this.state.clickStatus} />}</div>
			</React.Fragment>
		);
	}
}
export default Projects;
